import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

import pdfResume from '../assets/TKemme_Resume_2021.pdf'

const MenuLanding = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <Link to="/">
          <li>Home</li>
        </Link>
        <Link to="/#work">
          <li>Work</li>
        </Link>
        <Link to="/#about">
          <li
            onClick={() => {
              props.onToggleMenu()
              scrollTo('#about')
            }}
          >
            About
          </li>
        </Link>
        <Link to="/#contact">
          <li
            onClick={() => {
              props.onToggleMenu()
              scrollTo('#contact')
            }}
          >
            Contact
          </li>
        </Link>
        <li>
          <a href={pdfResume} target="blank" rel="noopener noreferrer">
            Resume
          </a>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
)

MenuLanding.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default MenuLanding
