import React from 'react'
import PropTypes from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'

import pdfResume from '../assets/TKemme_Resume_2021.pdf'

const Menu = props => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li
          onClick={() => {
            props.onToggleMenu()
            scrollTo('#banner')
          }}
        >
          Home
        </li>
        <li
          onClick={() => {
            props.onToggleMenu()
            scrollTo('#work')
          }}
        >
          Work
        </li>
        <li
          onClick={() => {
            props.onToggleMenu()
            scrollTo('#about')
          }}
        >
          About
        </li>
        <li
          onClick={() => {
            props.onToggleMenu()
            scrollTo('#contact')
          }}
        >
          Contact
        </li>
        <li>
          <a href={pdfResume} target="blank" rel="noopener noreferrer">
            Resume
          </a>
        </li>
      </ul>
    </div>
    <a className="close" onClick={props.onToggleMenu} href="javascript:;">
      Close
    </a>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
