import React from 'react'

const Footer = props => (
  <footer id="footer">
    <div className="inner">
      <ul className="icons">
        <li>
          <a
            href="https://github.com/tpkemme"
            className="icon alt fa-github"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">GitHub</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.linkedin.com/in/tylerkemme"
            className="icon alt fa-linkedin"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">LinkedIn</span>
          </a>
        </li>
      </ul>
      <ul className="copyright">
        <li>&copy; Tyler Kemme 2021</li>
        <div className="site-info">
          <span>
            This site was made with{' '}
            <a
              href="https://www.gatsbyjs.org/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GatsbyJS
            </a>
            ,{' '}
            <a
              href="https://app.netlify.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Netlify
            </a>
            , and ❤️.
          </span>
        </div>
      </ul>
    </div>
  </footer>
)

export default Footer
